<template>
  <div
    class="bp-quantity-input flex--row-space-between-center"
    :class="{
      'bp-quantity-input--no-border': noBorder,
      'bp-quantity-input--small': small,
      'bp-quantity-input--disabled': disabled
    }"
  >
    <button
      class="bp-quantity-input__button bp-quantity-input__button--decrease"
      :class="classButtonDecrease"
      type="button"
      :disabled="!canDecrease"
      @click="incrementDecrease"
      aria-label="Decrease quantity"
    />
    <input
      :disabled="disabled"
      ref="input"
      class="bp-quantity-input__input text--w-demi text-zd-button-large"
      :class="classInput"
      type="number"
      :value="currentValue"
      min="1"
      :max="max"
      :step="step"
      autocomplete="off"
      @paste="handlePaste"
      @change="changeValue"
    />
    <button
      class="bp-quantity-input__button bp-quantity-input__button--increase"
      :class="classButtonIncrease"
      type="button"
      :disabled="!canIncrease"
      @click="incrementIncrease"
      aria-label="Increase quantity"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    max: {
      type: Number,
      default: 99
    },
    step: {
      type: Number,
      default: 1
    },
    classButtonDecrease: String,
    classButtonIncrease: String,
    classInput: String,
    small: Boolean,
    noBorder: Boolean,
    disabled: Boolean,
    min: {
      type: Number,
      default: 1
    }
  },

  data: () => ({
    currentValue: null
  }),

  mounted() {
    this.setValue(this.value)
  },

  methods: {
    handlePaste(evt) {
      evt.preventDefault()
      return false
    },

    changeValue(evt) {
      this.setValue(Math.min(this.max, Math.max(1, evt.target.value)))
    },

    incrementDecrease() {
      if (this.canDecrease) {
        this.setValue(Math.max(this.min, this.currentValue - this.step))
      }
    },

    incrementIncrease() {
      if (this.canIncrease) {
        this.setValue(Math.min(this.max, this.currentValue + this.step))
      }
    },

    setValue(val) {
      const oldVal = this.currentValue
      let newVal = Math.round(val)
      this.currentValue = newVal
      if (newVal === oldVal) this.$refs.input.value = newVal
      this.emitValue()
    },

    emitValue() {
      this.$emit("input", this.currentValue)
    }
  },

  computed: {
    canDecrease() {
      return this.currentValue && this.currentValue > this.min
    },
    canIncrease() {
      return this.currentValue && this.currentValue < this.max
    }
  },

  watch: {
    value(newVal, oldVal) {
      if (!!newVal && !!oldVal && newVal !== this.currentValue) {
        this.setValue(newVal)
      }
    }
  }
}
</script>
<style lang="scss">
$button-height-default: 34px;
$button-height-xl: 55px;
$button-height-small-default: 28px;
$button-height-small-xl: 32px;

.bp-quantity-input {
  height: $button-height-default; // TODO: These should be global vars, also applied to the buttons
  border: 0.5px solid getcolour(bleach_black);

  &--no-border {
    border: none;
  }

  &--small {
    height: $button-height-small-default; // TODO: These should be global vars, also applied to the buttons
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  @include media-breakpoint-up(xl) {
    height: $button-height-xl;
    // padding: 0 20px;
    &--small {
      height: $button-height-small-xl;
      // padding: 0 12px;
    }
  }

  &__button {
    position: relative;
    flex: 0 0 $button-height-default;
    width: $button-height-default;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    body:not(.mouse-nav) &:focus {
      outline: 2px solid $bleach-peach;
    }

    .bp-quantity-input--small & {
      flex: 0 0 $button-height-small-default;
      width: $button-height-small-default;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 0 $button-height-xl;
      width: $button-height-xl;

      .bp-quantity-input--small & {
        flex: 0 0 $button-height-small-xl;
        width: $button-height-small-xl;
      }
    }

    &::before,
    &::after {
      background-color: $bleach-black;
      content: "";
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.15s;
    }

    &::before {
      height: 2px;
      width: 30%;
      max-width: 14px;
    }
    &::after {
      height: 30%;
      max-height: 12px;
      width: 2px;
    }

    &--decrease {
      &::after {
        visibility: hidden;
      }
    }
  }

  &__input {
    flex: 1 0 auto;
    -moz-appearance: textfield;
    background-color: transparent;
    height: 100%;
    width: 2em;
    text-align: center;
    border: none;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
</style>
